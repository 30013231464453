.experience-container {
  min-height: 100vh;
  background-color: rgb(230, 218, 206);
  animation: fade_in 1s;
}

.experience-sub-container {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
}

.experience-sub-container .heading {
  display: flex;
  align-items: center;
}

.experience-sub-container .section-divider {
  margin-top: 100px;
  margin-bottom: 40px;
}
.position-section {
  width: 50%;
}

.experience-sub-container .section-divider h3 {
  font-size: 25px;
  font-family: "Poppins-Bold";
}

.exp-blk {
  padding: 5px;
}
.experience-head {
  font-size: 35px;
  font-family: "Poppins-Bold";
  margin-left: 10px;
}

.experience-head,
.experience-sub-container,
.section-divider h3,
.experience-sub-container .timeline,
.experience-sub-container .job-title,
.experience-sub-container .company-name {
  color: #000;
}

.card .timeline {
  color: rgb(0, 80, 225);
  font-family: "Poppins-Bold";
  font-weight: 800;
  font-size: 16px;
  margin-bottom: 5px;
}

.card .job-title {
  font-family: "Poppins-Light";
  margin-bottom: 5px;
  text-transform: uppercase;
}

.card .company-name {
  font-family: "Poppins-Bold";
}

.experience-sub-container .details {
  flex: 0 0 35%;
}

.experience-sub-container .description {
  flex: 0 0 60%;
  color: #222;
}

.experience-sub-container .description li {
  margin-bottom: 10px;
}

.card .visit-certificate a {
  display: block;
  font-family: "Poppins-Bold";
  color: rgb(0, 80, 225);
  margin-top: 15px;
}

/* media queries*/

@media (max-width: 1024px) {
  .position-section {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .position-section {
    width: 90%;
  }
}

@media (max-width: 539px) {
  .experience-head {
    font-size: 29px;
  }

  .experience-sub-container {
    padding: 50px 0;
  }
  .experience-sub-container .card {
    padding: 40px 15px;

    flex-wrap: wrap;
  }

  .experience-sub-container .section-divider {
    margin-top: 40px;
  }

  .experience-sub-container .section-divider h3 {
    font-size: 18px;
  }

  .experience-sub-container .details,
  .experience-sub-container .description {
    flex: 0 0 85%;
    justify-content: center;
  }

  .experience-sub-container .description {
    margin-top: 20px;
  }

  .exp-blk {
    padding: 3px;
  }
}
