.navbar {
  display: flex;
  align-items: center;
  font-size: 15px;
  border-bottom: 1px solid #dde;
}

.navbar .nav-sub-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 50px;
}

.nav-title-container {
  display: flex;
  align-items: center;
}

.nav-name {
  line-height: 1.2em;
  font-family: "Poppins-Bold";
}

.nav-designation,
.nav-link-container a {
  text-transform: uppercase;
  letter-spacing: 0.12em;
  color: #000;
}

.nav-name,
.nav-designation,
.slash {
  margin-left: 8px;
}

.nav-link-container {
  margin-left: auto;
}

.nav-link-container a {
  margin-left: 15px;
  letter-spacing: 0.05em;
}

.link-active {
  padding-bottom: 2px;
  border-bottom: 2px solid rgb(0, 80, 225);
}

.navbar .mobile-nav {
  display: none;
}

.navbar .mobile-link-container {
  z-index: 100;
}

.navbar .mobile-link-container a {
  display: block;
  color: rgb(0, 80, 225);
  font-family: "Poppins-Bold";
  margin: 10px;
  text-transform: uppercase;
  animation: bottom_up 1s;
}

.link-active-mb {
  color: #000;
}

.animate-ham span:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 8.6px);
}

.animate-ham span:nth-child(2) {
  opacity: 0;
}

.animate-ham span:nth-child(3) {
  transform: rotate(45deg) translate(0.5px, -8.6px) scale(0.8);
}

.animate-ham .mobile-link-container {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  position: fixed;
  top: 10vh;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(244, 236, 230);
  animation: fade_in 1s;
}

/* Media Queries */

@media (max-width: 991px) {
  .navbar .slash,
  .navbar .nav-link-container,
  .navbar .mobile-link-container {
    display: none;
  }

  .navbar .nav-title-container {
    display: block;
  }

  .navbar .blk {
    margin-right: 5px;
  }

  .navbar .mobile-nav {
    display: block;
    margin-left: auto;
  }

  .navbar .nav-ham {
    cursor: pointer;
  }
  .navbar .nav-ham span {
    display: block;
    margin-left: auto;
    width: 22px;
    height: 2px;
    margin-bottom: 6px;
    background-color: rgb(0, 80, 225);
    color: rgb(0, 80, 225);
    transition: all 0.4s;
    border-radius: 5px;
  }

  .navbar .nav-ham span:nth-child(1) {
    width: 28px;
  }
  .navbar .nav-ham span:nth-child(3) {
    width: 33px;
  }
}

@media (max-width: 440px) {
  .navbar .nav-sub-container {
    margin: 0 20px !important;
  }

  .navbar .nav-name {
    font-size: 19px;
  }

  .navbar .nav-designation {
    font-size: 12px;
  }
}
