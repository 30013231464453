.project-container {
  min-height: 100vh;
  background-color: rgb(230, 218, 206);
  animation: fade_in 1s;
}

.project-sub-container {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
}
.project-sub-container .position-section {
  margin-top: 40px;
}

.project-head,
.project-sub-container .heading,
.project-sub-container .tech-stacks {
  color: #000;
}

.project-sub-container .heading {
  display: flex;
  align-items: center;
}

.project-blk {
  padding: 5px;
}

.project-head {
  font-size: 35px;
  font-family: "Poppins-Bold";
  margin-left: 10px;
}

.card .project-name {
  color: rgb(0, 80, 225);
  font-family: "Poppins-Bold";
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 20px;
}

.card .tech-stacks {
  font-family: "Poppins-Light";
  margin-bottom: 5px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  margin-bottom: 20px;
}

.card .project-links {
  display: flex;
  gap: 30px;
}
.card .visit-project a {
  font-family: "Poppins-Bold";
  color: rgb(0, 80, 225);
}

.project-sub-container .details {
  flex: 0 0 32%;
}

.project-sub-container .description {
  flex: 0 0 60%;
  color: #222;
}

.project-sub-container .description li {
  margin-bottom: 10px;
}

/* media queries */
@media (max-width: 1024px) {
  .position-section {
    width: 80%;
  }
}

@media (max-width: 820px) {
  .card .project-links {
    flex-direction: column;
    gap: 10px;
  }
}
@media (max-width: 768px) {
  .position-section {
    width: 90%;
  }

  .card .project-links {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 539px) {
  .project-head {
    font-size: 29px;
  }

  .card .project-links {
    flex-direction: row;
    gap: 30px;
  }

  .project-sub-container {
    padding: 50px 0;
  }

  .project-sub-container .card {
    padding: 40px 15px;
    flex-wrap: wrap;
  }

  .project-sub-container .details,
  .project-sub-container .description {
    flex: 0 0 80%;
    justify-content: center;
  }

  .project-sub-container .description {
    margin-top: 30px;
  }
  .project-blk {
    padding: 3px;
  }
}
