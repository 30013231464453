.home-container {
  width: 100%;
  min-height: 80vh;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  position: relative;
  animation: fade_in 1s;
  color: #000;
  background-color: #fff;
}

.bg-color {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 40%;
  background-color: rgb(230, 218, 206);
}

.home-wrapper {
  display: flex;
  flex: 0 0 100%;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #fff;
}

.home-wrapper img {
  max-width: 100%;
}
/* -----------------------Image Container------------------------ */
.imgContainer,
.descContainer {
  width: 375px;
  height: 525px;
  margin: 0 15px;
  position: relative;
}

.imgContainer {
  background-color: rgb(244, 236, 230);
  box-shadow: rgb(138 131 124 / 23%) -11.31px 11.31px 17px 0px;
  z-index: 99;
}

.imgContainer * {
  display: block;
  text-align: center;
  margin: auto;
}

.img-container {
  margin-top: 50px;
}

.img-container img {
  height: 210px;
  border-radius: 10px;
}
.imgContainer .social-media {
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: 15px 0;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.social-media img,
.social-media a {
  display: block;
  width: 26px;
  height: 26px;
  margin: 0 10px;
}

.imgContainer .name-container {
  margin: 35px 0 25px;
  font-weight: 800;
}

.imgContainer h2 {
  line-height: 1.2em;
  font-family: "Poppins-Bold";
  color: #000;
}

.imgContainer .break-line {
  width: 70px;
  height: 2px;
  background-color: rgb(0, 80, 225);
  margin-bottom: 25px;
}

.imgContainer .name-designation {
  margin-bottom: 50px;
  letter-spacing: 0.25em;
  font-size: 16px;
  font-family: "Poppins-light";
  font-weight: 100;
  text-transform: uppercase;
  color: #000;
}

/* -----------------------Description Container------------------------ */

/* .descContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
} */
.descContainer .headline-1 {
  font-size: 100px;
  font-family: "Poppins-Bold";
}

.descContainer .headline-2 {
  font-size: 25px;
  margin-bottom: 30px;
  font-weight: 500;
}
.descContainer .btn-container {
  margin-bottom: 45px;
}

.descContainer .btn-container a {
  margin-right: 10px;
}

.descContainer .btn-container button {
  width: 125px;
  height: 35px;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 20px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 600;
  transition: all 0.5s;
}

.descContainer .btn-container .resume-btn {
  background-color: rgb(0, 80, 225);
  color: #fff;
  border: 2px solid rgb(0, 80, 225);
}

.descContainer .btn-container .resume-btn:hover {
  border: 2px solid rgb(0, 80, 225);
  background-color: #fff;
  color: #000;
}
.descContainer .btn-container .project-btn {
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
}
.descContainer .btn-container .project-btn:hover {
  background-color: rgb(0, 80, 225);
  color: #fff;
  border: 0;
}

.descContainer .bio-paragraph {
  line-height: 1.5em;
  font-size: 16px;
  letter-spacing: normal;
  font-family: "Poppins-light";
  margin-top: 15px;
  color: #333;
}

/* Media Queries */
@media (max-width: 786px) {
  .imgContainer {
    margin-top: 50px !important;
  }

  .descContainer {
    margin: 20px 0px !important;
  }
}
@media (max-width: 440px) {
  .imgContainer {
    margin-top: 30px !important;
  }
  .home-wrapper .imgContainer,
  .home-wrapper .descContainer {
    width: 320px;
    height: 500px;
  }

  .descContainer .headline-1 {
    font-size: 60px;
  }
  .descContainer .headline-2 {
    font-size: 19px;
  }

  .descContainer .btn-container {
    margin-bottom: 30px !important;
  }
}

@media (max-width: 325px) {
  .home-wrapper .imgContainer,
  .home-wrapper .descContainer {
    width: 300px;
    height: 500px;
  }
}
