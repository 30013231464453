@font-face {
  font-family: "Poppins-Regular";
  src: url("./assets/fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("./assets/fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "Poppins-Light";
  src: url("./assets/fonts/Poppins-Light.ttf");
}
@font-face {
  font-family: "Poppins-xb";
  src: url("./assets/fonts/Poppins-ExtraBold.ttf");
}
* {
  margin: 0;
  padding: 0;
  font-family: "Poppins-Regular";
}

a,
a:visited {
  text-decoration: none;
  color: #000;
}

.main-content {
  width: 100%;
}

.App {
  height: 100vh;
  position: relative;
}

.navbar,
.footer {
  height: 10vh;
}

.card {
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 80px 40px;
  box-shadow: rgba(138, 131, 124, 0.23) -11.31px 11.31px 25px 0px;
}
.blk {
  width: 10px;
  height: 10px;
  padding: 2.5px;
  background-color: rgb(0, 80, 225);
}

.loading-blk {
  text-align: center;
  margin-top: 50px;
  font-size: 20px;
}

.text-bold {
  font-family: "Poppins-Bold";
}

@keyframes fade_in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes bottom_up {
  0% {
    transform: translateY(30px);
  }

  100% {
    transform: translateY(0);
  }
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}
