.contact-container {
  min-height: 80vh;
  background-color: rgb(230, 218, 206);
  animation: fade_in 1s;
}

.contact-sub-container {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
}
.contact-sub-container .heading {
  display: flex;
  align-items: center;
}

.contact-sub-container .position-section {
  margin-top: 40px;
}

.contact-blk {
  padding: 5px;
  margin-right: 10px;
}
.contact-head {
  font-size: 35px;
  font-family: "Poppins-Bold";
}

.contact-sub-container .form {
  background-color: #fff;
  padding: 40px;
}

.contact-sub-container .form .details-container {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
}

.contact-sub-container .form label {
  display: block;
  flex: 0 0 45%;
  font-family: "Poppins-Bold";
  margin-bottom: 50px;
  color: #000;
  background-color: #fff;
}

.contact-sub-container .form input,
.contact-sub-container .form textarea {
  background-color: #fff;
  display: block;
  width: 100%;
  outline: none;
  border: 0;
  border-bottom: 1px solid #000;
  margin-top: 10px;
  font-size: 16px;
  font-family: "Poppins-Light";
  letter-spacing: 0.06em;
}

.contact-sub-container .form .label-textarea {
  width: 100%;
  margin-bottom: 30px;
}

.contact-sub-container .form .send_default {
  padding: 10px 15px;
  color: #fff;
  border-radius: 15px;
  outline: none;
  transition: 0.3s;
  text-transform: uppercase;
  font-family: "Poppins-Bold";
  font-size: 14px;
  cursor: pointer;
}

.contact-sub-container .send {
  border: 1px solid rgb(0, 80, 225);
  background-color: rgb(0, 80, 225);
}

.contact-sub-container .send_inactive {
  border: 1px solid #6a9ff6;
  background-color: #6a9ff6;
  cursor: not-allowed;
}

.contact-sub-container .success-blk {
  position: absolute;
  bottom: 80px;
  background-color: rgb(27, 103, 243, 10);
  color: #fff;
  padding: 10px;
  animation: bottom_up 2s;
  border-radius: 10px;
}

.display-none {
  display: none;
}

/* media queries */

@media (max-width: 539px) {
  .contact-head {
    font-size: 29px;
  }

  .contact-sub-container .form {
    padding: 25px;
  }

  .contact-sub-container .details-container {
    flex-wrap: wrap;
  }

  .contact-sub-container .form label {
    flex: 0 0 100%;
  }

  .contact-sub-container .send {
    padding: 8px 12px;
    font-size: 12px;
  }

  .contact-sub-container .success-blk {
    bottom: 60px;
    padding: 12px;
    font-size: 14px;
  }
}
